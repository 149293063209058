//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import gql from 'graphql-tag'
import GlobalVideo from '../components/GlobalVideo.vue'
import { checkDeviceId } from '@/api/details'
const detailsGql = gql`query details($handle: String!){
  product(handle:$handle){
    title
    descriptionHtml
    options(first:250){
      id
      name
      values
    }
    variants(first:250){
      edges{
        node{
          title
          id
          priceV2{
            amount
            currencyCode
          }
        }
      }
    }
    images(first:1){
      edges{
        node{
          url
        }
      }
    }
  }
}`
const checkoutCreateGgl = gql`mutation checkoutCreate($input: CheckoutCreateInput!){
  checkoutCreate(input:$input){
    checkout{
      id
      webUrl
    }
    checkoutUserErrors{
      code
      field
      message
    }
  }
}`
const checkoutCustomerAssociateGql = gql`mutation checkoutCreate($checkoutId: ID!,$customerAccessToken:String!){
   checkoutCustomerAssociateV2(checkoutId: $checkoutId, customerAccessToken: $customerAccessToken) {
    checkout {
      webUrl
    }
    checkoutUserErrors {
      code
      field
      message
    }
  }
}`
export default {
  components:{
    GlobalVideo
  },
  data() {
    return {
      spinning:false,
      showPopover: false,
      loading:{create:false},
      merchandiseId:null,
      skuList: [],
			variationsData:[],
      specList: [],
      params:{},
      priceV2:{
        amount:null,
        currencyCode:null
      },
      disabled:false,
      rules: {
        value: [
          { required: true, message: this.$t("details.verify_1")},
          { min: 15, max: 21, message: this.$t("details.verify_2")},
          // { validator: this.validatePass, message: this.$t("details.verify_3") },
        ],
      },
      form: {
        value: null,
      },
      product:{
        descriptionHtml:null,
        options:[
          {name:null,values:[]},
          {name:null,values:[]},
        ],
        images:{
          edges:[{node:{url:null}}]
        }
      },
    }
  },
  watch:{
    '$route.query.handle'(){
      this.getData()
    },
    '$i18n.locale'(){
      this.$router.push({name: 'Home'})
    }
  },
  created(){
    this.getData()
  },
  methods: {
    async submit(){
      if(!await this.$refs.ruleForm.validate('value')) return
      checkDeviceId({ sim: this.form.value }).then(res => {
        if(res.code === 1){
          this.createCheckout()
        }else if(res.code == 4001){
          // this.$router.push({path: '/apology'})
          const routeData = this.$router.resolve({name: "Apology"});
          setTimeout(() => window.open(routeData.href, '_blank'),200)
        }else{
          this.$message.warning(this.$t("details.verify_3") + ' ' + res.code);
        }
      })
    },
    async createCheckout(){
      if(await this.$refs.ruleForm.validate('value')){
        this.loading.create = true
        this.$apollo
          .mutate({
              // 更新的语句
              mutation: checkoutCreateGgl,
              // 实参列表
              variables: {
                "input":{
                  "lineItems": {
                    "customAttributes": {
                      "key": "ICCID",
                      "value": this.form.value
                    },
                    "quantity": 1,
                    "variantId": this.merchandiseId
                  }
                }
              }
          }).then(response => {
              if(response.data.checkoutCreate.checkoutUserErrors.length>0){
                this.loading.create = false
                response.data.checkoutCreate.checkoutUserErrors.forEach(item => {
                  this.$message.error(item.message)
                });
              }else{
                if (this.$store.state.login&&this.$cookie.get('TSimAccessToken')){
                  this.checkoutAssociate(response.data.checkoutCreate.checkout.id)
                }else{
                  this.loading.create = false
                  
                  if(navigator.userAgent.toLowerCase().match(/Mobile/i)=="mobile"){
                    window.location.href = response.data.checkoutCreate.checkout.webUrl
                  }else{
                    window.open(response.data.checkoutCreate.checkout.webUrl,"_blank");
                  }
                }
              }
          }).catch(error => {
              this.$message.error(error.message)
          });
      }
    },
    checkoutAssociate(checkoutId){
      this.$apollo
        .mutate({
            // 更新的语句
            mutation: checkoutCustomerAssociateGql,
            // 实参列表
            variables: {
             "checkoutId": checkoutId,
              "customerAccessToken": this.$cookie.get('TSimAccessToken')
            }
        }).then(response => {
          this.loading.create = false
          if(response.data.checkoutCustomerAssociateV2.checkoutUserErrors.length>0){
            response.data.checkoutCustomerAssociateV2.checkoutUserErrors.forEach(item => {
              this.$message.error(item.message)
            });
          }else{
            if(navigator.userAgent.toLowerCase().match(/Mobile/i)=="mobile"){
              window.location.href = response.data.checkoutCustomerAssociateV2.checkout.webUrl
            }else{
              window.open(response.data.checkoutCustomerAssociateV2.checkout.webUrl,"_blank");
            }
          }
        }).catch(error => {
            this.$message.error(error.message)
        });
    },
    getProductVariations(array) { //获取产品规格对应的详细信息
				this.variationsData = array
				this.skuList = array.map((item, index)=>{
					const specs = item.node.title.split('/').map(value => value.trim());
					return {
							id: index + 1,
							specs: specs
						};
				})
		},
    getData(){
      this.spinning = true
      this.$apollo.addSmartQuery('product', {
        query: detailsGql, variables: {
          handle: this.$route.query.handle,
        }, result({ data, loading, networkStatus}) {
            this.spinning = false

            if(this.areAllValuesEmpty(data.product.options)){//防止values中没有数据导致页面上无法选择，若values中没有数据则从variants中逆推选项展示在页面上
              const titles = data.product.variants.edges.map(item => item.node.title);
              // 遍历每个 title，将其各部分存入对应的 options.values
              for (let i = 0; i < titles.length; i++) {
                  const parts = titles[i].split(' / ');
                  for (let j = 0; j < parts.length; j++) {
                    data.product.options[j].values.push(parts[j]);
                  }
              }
              // 去重
              data.product.options.forEach((option, index) => {
                  option.values = [...new Set(option.values)];
              });
            }

            this.product = data.product
            if(data.product.variants&&data.product.variants.edges.length){
              this.getProductVariations(data.product.variants.edges)
              data.product.options.forEach(item => {
								this.$set(this.params, item.name,'');
							});
              //处理规格选择信息
							this.specList = data.product.options.map(item => {
								return {
									name: item.name,
									options: item.values.map(its => {
										return {
											name: its,
											//  判断是否可以选择
											//  这里相当于init 初始化数据  this.isAble() 核心判断逻辑  
											able: setTimeout(() => {//定时器避免数据未加载
												this.isAble(item.name, its)
											},300)
										}
									})
								}
							})
            }

            // this.handleDaySelection(this.params.Day)
            this.matchStr()
        },// 错误处理
        error(error) {
            this.spinning = false
            this.$message.error(error.message)
        }
      });
    },
    areAllValuesEmpty(options) {//判断options中的values是否为空
        return options.every(option => option.values.length === 0);
    },
    isAble(key, value) {
				// 深拷贝 避免被影响  
				var copySelectSpec = JSON.parse(JSON.stringify(this.params));
				// 直接赋值当前验证项
				copySelectSpec[key] = value;
				// 用数组的 some 方法符合条件直接退出循环
				let flag = this.skuList.some(item => {
					// 条件判断 核心逻辑判断
					var i = 0;
					// 这个for in 循环的逻辑原理就是循环已经选中的和正在当前对比的数据和所有的sku对比只有当前验证的所有项满足sku中的规格或者其他规格为空时即满足条件
					for (let k in copySelectSpec) {
						if (copySelectSpec[k] != "" && item.specs.includes(copySelectSpec[k])) {
							i++
						} else if (copySelectSpec[k] == "") {
							i++;
						}
					}
					return i == this.specList.length
				})
				return flag
		},


    select(key, value, able){
      if (!able) return
			if (this.params[key] == value) {
				this.params[key] = ''
			} else {
				this.params[key] = value
			}
			// forEach循环改变原数组 
			this.specList.forEach(item => {
				item.options.forEach(its => {
					its.able = this.isAble(item.name, its.name);
				});
			});
      this.matchStr();
      this.$forceUpdate();
    },
    matchStr(){
      let str = ""
      for (const key in this.params) {
        if (Object.hasOwnProperty.call(this.params, key)) {
          str += this.params[key].replace(/\s*/g,"") + '/';
        }
      }
      for (let index = 0; index < this.product.variants.edges.length; index++) {
        if(str.slice(0,str.length-1) === this.product.variants.edges[index].node.title.replace(/\s*/g,"")) {
          this.merchandiseId = this.product.variants.edges[index].node.id
          this.priceV2 = this.product.variants.edges[index].node.priceV2
          this.disabled = false
          return
        }else{
          this.priceV2 = {amount:null, currencyCode:null}
          this.disabled = true
        }
      }
    },
    validatePass (rule, value, callback) {
      checkDeviceId({ sim: value }).then(res => {
        if(res.code === 1){
          callback();
        }else if(res.code == 4001){
          callback();
          return 4001
        }else{
          callback(new Error(this.$t("details.verify_3")));
        }
      })
    },
  }
}
